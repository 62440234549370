<template>
  <el-row class="comment-box shadowed">
    <el-col :span="24">
      <el-row type="flex" justify="space-between" align="middle" class="top">
        <el-col :span="isMobile ? 3 : 1" class="text-center">
          <el-avatar 
            :size="isMobile ? 30 : 38" 
            :src="photoUrl"
          />
        </el-col>
        <el-col>
          <p class="person-detail">{{ getFullName(commentObj.user) }} <i class="el-icon-time"></i> <small>{{ timeElapsed }}</small></p>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="isMobile ? 24 : 23" :offset="isMobile ? 0 : 1">      
      <p>{{ commentObj.text }}</p>
    </el-col>
  </el-row>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin.js"
import { timeElapsed } from "@/utils/helpers.js"

const emptyPhotoUrl = require('@/assets/pngs/user-s.png')

export default {
  name: "CommentBox",
  mixins: [generalMixin],
  props: {
    commentObj: { type: Object, required: true },
  },
  data() {
    return {}
  },
  computed: {
    timeElapsed() {
      return timeElapsed(this.commentObj.createdDate)
    },
    photoUrl() {
      let result = this.commentObj.user?.photo?.upload?.url
      if (result == null) result = emptyPhotoUrl
      return result
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
.comment-box {
  margin-bottom: 1.438rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 1rem;
  p {
    font: normal normal normal 16px/22px $fontBase;
    letter-spacing: 0px;
    color: #767396;
    padding-left: 0.5rem;

    &.person-detail {
      font: normal normal 600 16px/25px $fontBase;
      letter-spacing: 0px;
      color: #666C79;
      display: inline-block;
      small {
        font: normal normal normal 12px/12px $fontBase;
        letter-spacing: 0px;
        color: #868588;
      }
    }
  }
  .el-icon-time {
    margin-left: 0px;
    font-size: 12px;
  }
  .el-avatar {
    margin-right: 10px;
  }
}

.mobile {
  .top {
    margin-bottom: 1rem;
  }
  .comment-box .p {
    padding-left: 0;
  }
}
</style>