<template>
  <div class="comment-section">
    <el-row>
      <el-col :span="getColumn()" class="">
        <el-row class="comment-textbox">
          <el-col v-if="!isMobile" :span="2" :xs="4">
            <el-avatar 
              :size="isMobile ? 40 : 58"
              :src="photoUrl"
            />
          </el-col>
          <el-col v-loading="isLoading" :span="22" :xs="24">
            <el-input 
              v-model="commentText"
              type="textarea"
              resize="none"
              :rows="4"
              :disabled="!isAuthenticated || disabled"
              :placeholder="!isAuthenticated ? $t('LOGIN_TO_COMMENT') : $t('COMMENT_PLACEHOLDER')"
            />
            <el-row 
              v-if="isAuthenticated" 
              class="post-button-row" 
              type="flex" 
              justify="end" 
              align="middle"
            >
              <el-col :span="isMobile ? 12 : 4">
                <el-button class="button primary w-100" :disabled="disabled"  @click="postComment()">
                  {{ $t("POST") }}
                </el-button>
              </el-col>
            </el-row>
          </el-col>
          <template v-if="!isAuthenticated">
            <el-col :span="24" class="login text-center">
              <el-button 
                class="btn-login" 
                :disabled="disabled"
                @click="goTo('/auth/sign-in')" 
                @auxclick.native="goTo('/auth/sign-in', true)"
              >
                {{ $t("LOG_IN") }}
              </el-button>
              <span v-show="!isMobile"> {{ $t("OR") }} </span>
              <el-button 
                class="btn-signup"
                :disabled="disabled"
                @click="goTo('/auth/sign-up')"
                @auxclick.native="goTo('/auth/sign-up', true)"
              >
                {{ $t("SIGN_UP") }}
              </el-button>
            </el-col>
          </template>
        </el-row>

        <el-divider content-position="left">{{ $t("COMMENTS") }}</el-divider>

        <template v-if="list.length == 0">
          <p class="empty-comment text-center">{{ $t("NO_COMMENT_FOUND") }}</p>
        </template>

        <template v-else>

          <transition-group name="comment">
            <commentBox
              v-for="el in list"
              :commentObj="el"
              :key="'comment_'+el.id"
            />
          </transition-group>

          <el-row type="flex" justify="center">
            <el-button 
              v-loading="isLoading"
              v-if="totalAvailable > totalLoaded"
              :class="{ pointer: !isLoading }"
              class="button primary reversed-state with-icon btn-load-more" 
              @click="loadMore()"
            >
              {{ $t("SEE_MORE") }}
              <i class="custom-icon icon-bubble mini"/>
            </el-button>
          </el-row>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import commentBox from "./comment-box.vue"
import { generalMixin } from "@/utils/general-mixin.js"
import { getCommentList, createComment } from "@/api/comment.js"
import { mapGetters } from "vuex";

const emptyPhotoUrl = require('@/assets/pngs/user-b.png')

export default {
  name: "CommentSection",
  components: {
    commentBox
  },
  mixins: [generalMixin],
  props: {
    articleId: { type: Number, required: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: false,
      list: [],
      totalLoaded: 0,
      totalAvailable: 1,
      commentText: "",
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
    photoUrl() {
      let result = this.user?.photo?.upload?.url
      if (result == null) result = emptyPhotoUrl
      return result
    }
  },
  watch: {
    articleId() {
      this.mountedFn()
    },
  },
  methods: {
    getColumn() {
      return this.isMobile ? 24 : 19
    },
    async getList(amount = 1) {
      let offset = this.totalLoaded
      const postData = {
        offset,
        orderCol: "created_date",
        limit: amount,
        desc: true,
        articleId: this.articleId,
        status: "ACTIVE"
      }
      if (this.categoryId != null) {
        postData.genericRecord = { id: this.categoryId }
      }
      let result = []
      await getCommentList(postData).then(res => {
        if (res.status == 'ok' && res.result?.list != null) {
          result = res.result.list
          this.totalAvailable = res.result.total
          this.totalLoaded += result.length
        }
      })
      return result
    },

    async loadMore() {
      if (this.isLoading) return
      const loadNum = 4

      this.isLoading = true
      let result = await this.getList(loadNum)
      this.isLoading = false

      this.list.push(...result)
    },
    reset() {
      this.list = []
      this.totalAvailable = 1
      this.totalLoaded = 0
      this.commentText = ""
    },
    async mountedFn() {
      this.reset()
      this.isLoading = true
      this.list = await this.getList(4)
      this.isLoading = false
    },
    postComment() {
      if (this.commentText == "" || this.commentText == null) {
        this.$notify({
          type: 'error',
          message: this.$t("COMMENT_CANNOT_EMPTY"), 
          duration: this.NOTIFY_DURATION,
          position: this.NOTIFY_POSITION
        })
        return
      }
      const postData = {
        user: { id: this.user.id },
        article: { id: this.articleId },
        text: this.commentText
      }
      this.isLoading = true
      createComment(postData).then(res => {
        if (res.status == 'ok') {
          this.$notify({
            type: 'success',
            message: this.$t("POSTED_SUCCESSFULLY"),
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })
          if (res.result?.coins && res.result?.transactionType === "IN") {
            this.$notify({
              title: this.$t("GAINED_COIN_TITLE"),
              message: this.$t("GAINED_COIN_MSG", { amount: res.result.coins }),
              type: 'success',
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION,
              dangerouslyUseHTMLString: true
            })
          }
          this.mountedFn()
        }
      })
      .finally(() => this.isLoading = false)
    }
  },
  mounted() {
    this.mountedFn()
  },
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

.comment-section {
  margin-top: 1.5rem;

  .el-divider__text.is-left {
    left: 0px;
    padding-left: 0;
    background-color: $bgColor;
    font: normal normal bold 18px/42px $fontBase;
    letter-spacing: 0px;
    color: #1C1D21;
  }

  .comment-textbox {
    margin-bottom: 1.5rem;
    .el-input--medium .el-textarea__inner {
      height: 36px;
      background: #EFEFEF 0% 0% no-repeat padding-box;
      border-radius: 5px;
      border: none;
      height: 4.2rem;      
      font-size: 18px;
      line-height: 20px;
    }
    ::placeholder {
      font: normal normal bold 18px/20px $fontBase; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      font: normal normal bold 18px/20px $fontBase;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      font: normal normal bold 18px/20px $fontBase;
    }
  }

  .empty-comment {
    padding: 2rem;
  }

  .el-avatar {
    float: left;
  }

  .post-button-row {
    padding: 8px;
  }

  .login {
    margin-top: 1.5rem;

    >span {
      padding: 0 2rem;
    }

    .btn-login,
    .btn-signup {
      min-width: 9rem;
      border-radius: 5px;
      border: 1px solid $secondaryText;
      font: normal normal 600 18px/16px $fontBase;
    }

    .btn-login {
      color: $secondaryText;
      background: $bgColor;
    }

    .btn-signup {
      color: $white;
      background: $secondaryText;
    }
  }

  .btn-load-more {
    font-weight: bold;
    i {
      margin-left: 5px;
    }
  }

  // Transition
  .comment-enter-active, .comment-leave-active {
    transition: all 0.5s;
  }
  .comment-enter, .comment-leave-to /* .comment-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateX(100%);
  }
}

.mobile .comment-section {
  .btn-signup {
    margin-left: 10px;
  }
}

</style>